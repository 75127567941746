@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-SemiBold.eot');
  src: url('../fonts/Poppins/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
      url('../fonts/Poppins/Poppins-SemiBold.woff') format('woff'),
      url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Bold.eot');
  src: url('../fonts/Poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
      url('../fonts/Poppins/Poppins-Bold.woff') format('woff'),
      url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-ExtraBold.eot');
  src: url('../fonts/Poppins/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins/Poppins-ExtraBold.woff2') format('woff2'),
      url('../fonts/Poppins/Poppins-ExtraBold.woff') format('woff'),
      url('../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.eot');
  src: url('../fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
      url('../fonts/Poppins/Poppins-Regular.woff') format('woff'),
      url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


